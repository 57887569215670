.contact {
  padding: 60px 0 200px 0;
  margin-top: 170px;
}
label {
  color: white;
  font-size: 20px;
  font-style: italic;
}
.contact img {
  width: 55%;
  margin-left: 96px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.contact h2 {
	font-size: 3rem;
	font-weight: 700;
  margin-bottom: 30px;
  color: white;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: #191a1a;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
    background: linear-gradient(90.21deg, #550080 -5.91%, #19285a 111.58%);
    color: #191a1a;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #191a1a;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #191a1a;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-bottom: -20px;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #191a1a;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}
/* input {
  hover: linear-gradient(90.21deg, #550080 -5.91%, #19285a 111.58%);
} */
