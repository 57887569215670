/* This is the app css file, all css designs in here apply to the entire application including all new pages */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Monocode-Regular-V01.02b';
  
}



body{
  /* background-color: */
  background: rgb(76,29,83);
  background: linear-gradient(90deg, rgb(36, 7, 65) 0%,#4b2272 55%, #887cac 100%);


/* background: rgb(105,1,205);
background: linear-gradient(90deg, rgba(105,1,205,1) 21%, rgba(163,20,180,1) 51%, rgba(209,56,186,1) 81%); */
  
}



