.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #2d0353 0%,
    #fff 29%,
    #6720a1 67%,
    #af9de6 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  font-weight: bold;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
  font-size: 80px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@media only screen and (max-width: 600px) {
    

    .animate-character {
        font-size: 20vw; /* Adjust the font size for smaller screens */
    }
}


.typewriter TopLine {
    overflow: hidden;
    border-right: .15em solid orange;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }
  