/* Button */


.btn {
    background-color: none;
    font-size: 19px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5000px;
    transition: all 0.3s;
}
